@import '../../styles/variables.scss';

.right-wrapper {
  display: inline-flex;
  min-width: 100px;
  justify-content: center;
  align-items: center;
  float: right;
  margin: 0 20px;

  .item {
    margin: 0 10px;
  }

  i {
    transition: 0.3s all ease-in-out;
    color: rgb(130, 130, 130);
    font-size: 18px;
    cursor: pointer;

    &:hover {
      color: $secondary_color;
    }
  }
}
