.back-office {
  .ant-menu.ant-menu-dark {
    background: #000000 !important;
  }

  .ant-layout-sider {
    background-color: #000000;
  }

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
    .ant-menu-item-selected {
    background-color: #662f9a;
  }
}
